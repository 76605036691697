.footer{
     background: #e2e5e7;
     color: rgb(37, 37, 37);
     padding:50px 20px;
    }
    
    .footerWrapper{
    display: flex;
    max-width: 1100px;
    margin: 0px auto;
}


.footerTop{
    flex: 1;
    margin-right: 20px;
}


.footerLogo{
    max-width: 150px;
}

.footerDescription{
    color: rgb(83, 83, 83);
    font-size: 15px;
    margin-top: 5px;
    padding-top: 10px;
    /* border-top: 2px solid #f2832b; */
    margin-bottom: 20px;
    max-width: 450px;
    
}

.footerImg{
    max-width: 100%;
}

.socialLinks{
    display: flex;
    align-items: center;
    margin: 10px auto;
}

.footerIcons{
    padding: 10px;
    
    margin-right: 10px;
    max-width:40px ;
    max-height:40px ;
    color: #f2832b;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    background: #f2832b;
    transition: all 0.3s ease-in-out;
    
}

.footerIcons:hover, .footerIcons:focus{
    border:0.5px dashed #f2832b;
    color: #f2832b;
    background: white;
    box-shadow: 0px 4px 19px -5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 4px 19px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 19px -5px rgba(0,0,0,0.75);
    
}


.footerSocialTitle{
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid rgb(116, 116, 116);
    margin-bottom: 20px;
    max-width: fit-content;
    padding: 5px;
}

/* footer bottom */


.footerBottom{
    flex: 1;
    background-color: #272727;
    color: #f2832b;
    border-radius: 10px;
    padding: 30px;
}

.footerBottomTitle{
    font-size: 15px;
    letter-spacing:0.5px;
    border-bottom:1px solid #f2832b;
    max-width: max-content;
    padding: 5px;
    font-weight: 600;
}


.footerBottomLinks{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
}

.footerBottomLinkTitle{
    font-size: 17px;
    font-weight: 400;
    color: #6b6b6b;
}

.footerBottomLinkList > li {
    font-size: 15px;
    cursor: pointer;
}

.footerBottomLinkList{
    margin-left: 10px;
}

.footerQuickLink{
    padding: 2px;
    cursor: pointer;
    
}
.footerQuickLink:hover{
    color: #3cbcf7;
}

@media only screen and (max-width:600px) {
    .footerWrapper{
        display: block;
    }
    .footerBottom
    {
        margin-top: 30px;
    }
    .footerBottomLinks{
        display:block;
    }
    .footerBottomLinkWrapper{
        margin-top: 20px;
    }
}
