/* #home{
 background-color: #E6F5F0;
display: grid;
grid-template-columns: 1fr 1fr;
    max-width: 100vw;
    position: relative;
    z-index: -2;
    height: 90vh;
    position: relative;
}



.owl-stage-outer img{

    height: 100vh !important;
}
#desc{
    z-index: 2;
}
.owl-nav,.owl-dots{

    display:none;
}
.heading{
    color: #456157;
    font-size:45px;
    list-style: 58px;
    font-weight: 500;
    max-width:350px;
}
.heading span{
    font-weight: 700;
    color:#F2832B;
    z-index:1;
}
.wave{
    position: absolute;
    bottom:0;
    z-index: -1;
}
.left__side{
    flex:0.5;
    text-align: left;
     display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left:15px;
    animation:leftRight 1.7s ease-in-out;
}
@keyframes leftRight {
    0%{
        opacity:0;
        margin-left:-50px;
    }
    100%{
        opacity:1;
        margin-left:0px;

    }
}
.left__side .supText{
    font-size:18px;
    color:#155257;
    line-height:26px;
    z-index: 1;
}
.subText{
    max-width:500px;
    color:#155257;
    margin-top: 12px;
    font-weight:400;
}
.right__side{
    flex: 0.5;
    overflow:hidden;
}
.right__side > img {
    width:100%;
    object-fit: cover;
    height: 100%;
}
.cta{
    margin-top:20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
     cursor:pointer;
}
.cta:hover .cta__text,
.cta:hover .cta__icon{
    color: rgb(51, 51, 51);
    transform: scale(1.1);
    transition:all 0.3s ease-in-out;
}
.cta__icon{
    width: 30px;
    border-radius: 50%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
}
.cta__text {
    font-weight: 400;
    font-size: 15px;
    margin-left: 15px;
    color: rgb(112, 112, 112);
}

@media only screen and (max-width:1060px) {
    #home{
        display:flex;
    flex-direction: column-reverse;
    height:100vh;
}
.owl-stage-outer img{

    height: 100% !important;
}
.heading{
    font-size:32px;
    list-style: 40px;
    font-weight: 400;
    max-width:400px;
    z-index:1;
}
}
#home{
    position:relative;
}
.right__side img{
    height:100%;
    width: 100vw !important;
    object-fit:cover;
}




/* Custom */


/* .left__side_home{
    position: absolute;
    top:20%;
    left:10%;
} */ 




/* custom */

#home {
    position: relative;
    max-height:90vh;
    overflow: hidden;
}
/* .overLay{
        position: absolute;
    top:0;
    z-index: 100;
    height: 90vh;
    width:100vw;

} */

.left__side_home{
    position: absolute;
    
    z-index: 101;
    max-width: 60vw;
    padding: 50px 60px;
height:90vh;
background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0.7727645702576369) 50%, rgba(255,255,255,0) 100%);
}

.owl-nav,.owl-dots{

    display:none;
}

.text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height:70vh;
    margin-left:20px;
}

.heading{
    color: #456157;
    font-size:55px;
    list-style: 58px;
    font-weight: 600;
    
}
.heading span{
    font-weight: 800;
    color:#F2832B;
    z-index:1;
    /* -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: rgb(255, 255, 255); */
}
.supText{
    font-size:18px;
       color: #456157;
    line-height:26px;
    z-index: 1;
}
.subText{
    max-width:500px;
    color:#ffffff;
    margin-top: 12px;
    font-weight:400;
}


.cta{
    margin-top:20px;
    display: flex;
    justify-content:center;
    align-items: center;
     cursor:pointer;
     padding:15px 10px ;
     border-radius: 10px;
     background-color:white;
     color:gray;
     border:1px solid #F2832B;
     max-width: 200px;
    box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.66);
-webkit-box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.66);
-moz-box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.66);
}
.cta__icon{
    margin-right: 10px;
    width:35px;
    height: 35px;
    border-radius: 50%;
    background-color: #F2832B;
    color:white;
    font-size: 22px;
    display:flex;
    align-items: center;
    justify-content: center;
}
.cta__text{
    font-size: 18px;
    
}

@media only screen and (max-width:1060px) {
    #home{
        height: 100vh;
    }
.left__side_home{
    
    max-width: 100vw;
    padding: 0px 0px;
 max-height:90vh;
background: rgb(255,255,255);
background: linear-gradient(4deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5929892893587605) 100%);
}
.text{
    height:90vh;
}
.owl-stage-outer img{

    height: 100vh !important;
    object-fit: cover;
}
.heading{
    font-size: 45px;
}
.cta{
    padding: 8px 10px;
}
}