.navbar {
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
    padding-top:10px;
    padding-bottom:10px;
    padding-left: 20px;
    padding-right: 20px;
    position: sticky;
    top:0;
    height: 75px;
    box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.48);
-webkit-box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.48);
-moz-box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.48);
z-index: 999;
}

.navbar .container{
    max-width:1200px;
    margin:0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.navbar .nav__logo > img{
    max-width:100px;
} 

.navbar .nav__liks-container{
    flex:0.7;
    }

.navbar .nav__liks-container > ul {
    display: flex;
    justify-content: space-around;
} 

.topBarLink{
    list-style: none;
    font-weight:400;
    font-size:18px;
    color:#677b8c;
    line-height:50px;
    transition:all 0.3s ;
    border-bottom: 2px solid #fff;
    cursor: pointer;
    display: flex;
    
}


.topBarLink:hover{
    color:rgb(19, 19, 19);
    border-bottom: 2px solid rgb(255, 210, 126);
}

.navbar .nav__liks-container > ul > .active{
    color:rgb(19, 19, 19);
    border-bottom: 2px solid rgb(255, 210, 126);
}
.menu{
    display: none;
}

@media only screen and (max-width:1060px) {
    body{
        overflow-x: hidden;
    }
   
   .menu{
       display: block;
       font-size:25px;
       cursor: pointer;
       color:#F2832B;
   }
   .color_black{
       color: black;
   }
.nav__logo{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navbar .container{
    max-width:1200px;
    margin:0px auto;
    display: block;
    
}

.navbar .nav__logo > img{
    max-width:120px;
} 


.navbar .nav__liks-container > ul {
    display:block;
    height:100vh;
         /* background-color: bisque; */
         padding-top:20px;

         
        } 
            
.topBottom{
    background:white;
    width: 100vw;
        margin-left: -20px !important;
}
.d-block{
    display: block;
}
.d-none{
    display: none;
}
@keyframes topBottom {
    0%
    { 
        opacity: 0;
        margin-top: -150px;

    }
    100%{
        opacity: 1;
        margin-top: 0px;
    }
}
 
.navbar .nav__liks-container > ul > li{
    list-style: none;
    font-weight:400;
    font-size:18px;
    color:#677b8c;
    line-height:50px;
    transition:all 0.3s ;
     border-bottom: none;
     cursor: pointer;
     padding-left: 20px;
}

.navbar .nav__liks-container > ul > li:hover{
    color:rgb(19, 19, 19);
    padding-left: 5px;
    border:none;
}
.navbar .nav__liks-container > ul > .active{
    color:rgb(19, 19, 19);
    border:none;
}
}

.dropDown{
    position: relative;
}

.dropdownArrow{
    display: flex;
    align-items: center;

}


ul{
    list-style: none;
}


@keyframes topBottom {
    0%{
        opacity: 0;
        bottom: 0px;

    }100%{
        opacity: 1;
        top:65px;
    }
}
.iconArrow{
 
     font-size: 16px !important;
     margin-left: 5px;
}



@keyframes rotate {
    0%{
        transform: rotate(0deg);

    }100%{
      transform: rotate(90deg);
    }
}

@media only screen and (max-width:1060px) {
.serviceDropDown{
    position: static;
    display: none;
    }
    .dropDown:focus .serviceDropDown{
        display: block;
    }
}
@media only screen and (min-width:1060px) {
    
.dropDown:hover .icon{
    animation: rotate 0.5s ease-in-out;
    transform: rotate(90deg);
    font-size: 16px !important;
}

.dropDown:hover .serviceDropDown{
    
    display: block;
    opacity: 1;
    animation: topBottom 0.5s ;
}
.serviceDropDown{
    position: absolute;
    padding: 10px;
    background: #fff;
    
    display: none;
    width:230px;
    top:65px;
}
}