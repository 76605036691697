

.servicesTabs{
      
    padding: 20px ;
    margin-left: 15px;  
    padding-bottom: 25px;
    
}

.servicesTabTitle{
    font-weight: 700;
    font-size: 30px;
    font-variant: small-caps;
    margin-bottom: 5px;
    color:#f2832b;
    padding: 10px 0px ;
    border-bottom: 3px solid #E29400;
    margin-bottom: 15px;
}


.servicesTabDesc{
    font-size: 13px;
    color: gray;
}

.servicesTabSubTitle{
    font-size: 18px;
    font-weight: 600 !important;
    letter-spacing: 2px;
    margin-top: 20px;
       margin-bottom: 10px;
}
.MuiTab-textColorPrimary.Mui-selected {
    /* color: #f2832b !important; */
    background-color: azure;
}

.incs{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
    grid-row-gap: 10px;
}

.servicesIncsData{
    display:flex;
    align-items: center;
    height:100%;
    background: #fff;
    border: 1px dashed gray ;
    border-radius: 10px;
    padding:8px 10px;
    padding-left: 20px;
    border:2px dashed #ebba27;
    color:#E29400;
    background: #FFFADB;
    max-width: 80%;
  
}
@media only screen and (max-width: 600px ) {
        .incs{
            grid-template-columns: 1fr 1fr;
             grid-gap: 5px;
        }
        .servicesIncsData{
            max-width: 100% !important;
        }

}


.servicesIncsIcon{
    margin-right: 10px;
    margin-right:5px;
    font-size: 30px;
    
    font-weight: 400px;
    z-index: 0;
}

.servicesIncsTitle{
 font-size:15px;
    flex:1;
}

.includesList{
    font-size: 15px;
}

.ul{
    list-style: circle;
    margin-left: 30px;
 
}


.ol{
   margin: 20px; 
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
}

.partsTitle{
    font-size: 20px;
    font-weight: 600;
    color: rgb(37, 37, 37);
}

.partsDesc{
    font-size: 15px;
    font-weight: 400;
    color: gray;
}

.partsLi{
    margin-bottom: 10px;
}