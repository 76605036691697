#subService{
    margin:0px auto;
    max-width: 1400px;
    padding:50px;
  
}
body{
     position:relative;
}
.inside_subService{
    margin:0px auto;
    max-width:1000px;
    background-color: white;
    padding:20px;
    border-radius: 10px;
}

.subservice_heading{
    font-size:20px;
    color: #456157;
    text-align: center;
    
}
.subservice_heading h1{
    background:white;
    border-radius: 10px;
}

.subservice_heading p{
    font-size:15px;
    margin:5px auto;
    max-width:800px;
}

.subservice_details{
    margin-top:25px;
}


.details_heading {
    font-size:20px;
    color: #456157;
    text-align: center;
}

.subservice_proccesses{
    display:grid;
    grid-template-rows: 1fr 1fr ;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap:10px ;
    grid-row-gap:10px ;
    
}


.subservice_proccess{
    display:flex;
    align-items: center;
    height:100%;
    background: #fff;
    border: 1px dashed gray ;
    border-radius: 10px;
    padding:10px 10px;
    border:2px dashed #ebba27;
           color:#E29400;
    background: #FFFADB;
}

.icon{
    margin-right:5px;
    font-size: 30px;
    flex:0.5;
    font-weight: 400px;
    z-index: 0;
}
.subservice_right_side{
    font-size:15px;
    flex:1;
    
}




@media only screen and (max-width:600px) {
    .subservice_proccesses{
    display:grid;

    
    grid-template-columns: 1fr;
    grid-column-gap:10px ;
    grid-row-gap:10px ;
    margin-top:10px;
}
}

.subservice_left_side{
    position: relative;
}

.back_round{
    position: absolute;
    top:0;
    width:50px;
    height:50ox;
    background-color: #4f4f4f;
}



.viewmore button{
    
    display:flex;
    justify-content: center;
    align-items: center;
    margin:0px auto;
    margin-top:20px;
    background: none;
    outline:none;
    border:none;
}

.viewmore{
    text-align: center;
}


