.dropDown{
    background-color:white;
    position: absolute;
    padding:15px;
    border-radius:10px;
    box-shadow: -1px 1px 18px 0px rgba(197,197,197,0.75);
-webkit-box-shadow: -1px 1px 18px 0px rgba(197,197,197,0.75);
-moz-box-shadow: -1px 1px 18px 0px rgba(197,197,197,0.75);
    
    top:70px;
}

.dropDownlink{
    font-size: 16px;
    color: rgb(255, 174, 0);
    
}


.dropDownlink:hover{
    background: blue !important;
}


.list ul{
    list-style:circle;
}

@media only  screen and (max-width: 600px) {
    .dropDown{
        position:relative;
         top:0px;
         width: 100%;
         margin: 10px;
    }
}