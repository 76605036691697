.top__bg{
    height:150px;
    width:100%;
    background-color:#E98944;
    /* color:#E98944; */
}

.bottom{
    max-width:900px;
    margin:0px auto;
    padding:6px 5px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.75);
}

.how_heading{
    padding:20px;
    text-align: center;
    background-color: white;
    border-radius: 12px;
    color:white;
    /* border:4px solid white; */
    margin:0px 5px;

}
.proccess{
    max-width: 800px;
    margin: 0px auto;
    padding:10px;
}

.process__card{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin:5px;
    
}

.process__card:nth-child(even){
    flex-direction:row-reverse;
        margin:10px;
}

.process__cardImg{
    flex: 0.2 ;
}
.process__cardImg img{
    max-width: 98%;
    object-fit: contain;
}
.process__cardtext{
    margin:0px 5px;
    flex: 1 ;
    color:#31636E;
}
.process__cardtext h2{
    color:#31636E;
}
@media only screen and (max-width:600px) {
    .process__card{
    flex-direction:column;
}
.process__card:nth-child(even){
    flex-direction:column;
}
.process__cardImg img{
    max-width: 58%;
    object-fit: contain;
}
.bottom{
    box-shadow:none;
    }
    .how_heading{
        font-size:18px;
        font-weight:700;
        letter-spacing:1px;
    }
    .top__bg{
        display:none;
    }
    .how{
        margin-right:5px ;
        margin-left:5px;
    }
}
