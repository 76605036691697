
@keyframes ldio-a0kwqp8gqya {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}
.ldio-a0kwqp8gqya div {
  position: absolute;
  animation: ldio-a0kwqp8gqya 1s linear infinite;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #fd8e25;
  transform-origin: 80px 82px;
}
.loadingio-spinner-eclipse-ir0xk54kzr {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #f1f2f3;
}
.ldio-a0kwqp8gqya {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-a0kwqp8gqya div {
     box-sizing: content-box; 
    overflow: hidden;
    }

/* generated by https://loading.io/ */

.loading{
    width: 100vw;
    height: 100vh;
    background: #f1f2f3;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.loading_logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width:100px ;
}