.contactUs{
    background: #000;
}
#contact_container{
    padding:25px 5px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 1100px;
    margin: 0px auto;
}
.reach_out{
    max-width: 100% ;
    flex: 0.6;
    background-color: aqua;
    padding:20px;
}
#form {
    flex: 0.5;
    text-align: center;

}
.contact__heading{
  font-size:35px;
    font-weight: 700;
    text-align: center;
    color: #456157;
}
#form input,
#form textarea {
    background-color: transparent;
    border: none;
    outline :none;
    border: 2px solid rgb(162, 162, 162);
    width: 100%;
    padding:10px 10px;
}
input[type=text], select, textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid #ccc;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  border-radius:8px;
  outline-color:orange;
}

input[type=submit] {
  font-size:18px;
    
    padding:10px 15px ;
    cursor: pointer;
    color:white;
    border-radius: 10px;
    border:none;
    outline:none;
    background-color:#F2832B;
  }
  
  input[type=submit]:hover {
  background-color:#d17427;

}

/* Style the container/contact section */
.contact_container {
  border-radius: 5px;

  padding: 10px;
}

/* Create two columns that float next to eachother */
.column {
  flex:1;
  
}

.row{
  display:flex;
  margin:20px;
  background-color:white !important;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */

.error{
  color: rgb(255, 64, 64);
}

.contact_img{
  max-width:100%;
  height:100%;

  margin-right:40px;
  padding:20px;
  object-fit:cover;
}

.img_column{
  overflow:hidden;
  border-radius:10px ;
}

.contact_form{
  padding:10px;
}



@media only screen and (max-width: 600px) {
  .column, input[type=submit] {
    width: 100%;
    margin-top: 0;
  }
  .row{
  display:block;
  
}
.contact_form{
  background-color:white;
  z-index:999;
}
.contact_img{
  width:100%;
  max-height:450px;
  object-fit: cover;
  z-index: 2;

}

.img_column{
  overflow:hidden;
  border-radius:10px ;
  z-index:-1 !important;
}

.contact_form{
  margin-top: -60px;
  padding:10px;
  z-index:30;
background-color:white;
}
}