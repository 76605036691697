.col-5{
    max-width: 600px;
    padding: 25px;
    margin: 10px auto;
    text-align: center;
   border-radius: 20px;
background-color: #85FFBD;
background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);

}
.choose__btn{
    padding: 10px ;
    color: white;
    background: #5e5e5e;
    border-radius: 10px;
    cursor: pointer;
    font-size:20px;
    width: 100%;
    display: flex;
    justify-content:center;
    align-items: center;
     border:2px solid  transparent;
}
.choose__btn:hover{
    transition: all 0.3s ease-in-out;
   background: none;
   border:2px solid  #5e5e5e;
   color:  #5e5e5e;
   box-shadow: -2px 2px 33px -7px rgba(0,0,0,0.75);
-webkit-box-shadow: -2px 2px 33px -7px rgba(0,0,0,0.75);
-moz-box-shadow: -2px 2px 33px -7px rgba(0,0,0,0.75);
/* background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%); */

}
.heading__title{
    font-size:38px;
    font-weight: 700;
    text-align: center;
    color: #456157;
}

.btn-submit{
    margin-top:10px;
    background-color:none;
    outline: none;
    border: none;
    background: #f2832b;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size:20px;
    cursor: pointer;

}

.btn-submit:hover{
   box-shadow: 10px 1px 20px 4px rgb(248, 244, 1);
-webkit-box-shadow: 2px 1px 20px -4px rgb(243, 149, 26) ;
-moz-box-shadow: 2px 1px 20px -4px rgb(166, 218, 84);
transition: all 0.7s ;
}

.outer{
    padding:30px ;
}   
.disabled{
    background: #456157;

}