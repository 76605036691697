.about__section{
    
    margin-bottom:15px !important;
    padding:20px;
}

.d-flex{
    display: flex;
}
.justify-center{
    justify-content: center;
}
.justify-between{
    justify-content:space-between;
}
.justify-evenly{
    justify-content:space-evenly;
}
.justify-around{
    justify-content:space-around;
}
.align-center{
    align-items: center;
}

.about__container{
    max-width: 1200px;
    
    margin:0px auto;
    max-height: 100%;
    margin-top:55px;
    margin-bottom: 55px ;
 box-shadow: 0px 13px 38px -26px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 13px 38px -26px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 13px 38px -26px rgba(0,0,0,0.75);
}
.text-align-center{
    text-align: center;
}
.text-align-left{
    text-align: left;
}
.text-align-right{
    text-align: right;
}
.left__side > img {
    width:100%;
    object-fit: cover;
    height: 100%;
}
.text__bottom{
  background-color: #0093E9;
background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);

    color: white;
    font-size: 22px;
}
.text__top{
    background-color: #FAD961;
background-image: linear-gradient(-160deg, #FAD961 0%, #F76B1C 100%);

    color: white;
    font-size: 22px;
}
.left__side{
    padding:20px;
}

@media only  screen and (max-width:860px) {
    .about__top{
        flex-direction: column-reverse ;
    }
    .about__bottom{
        flex-direction: column ;
        margin-top:30px;
    }
    .about__container{
    max-width: 1200px;
   
    margin-left:25px;
    margin-right:25px;
}
.text__top{
    
    font-size: 15px;
}
.text__bottom{
    margin-top: 0px;
    font-size: 15px;
}
}

.left__side{
    flex:0.5;
    text-align: left;
     display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left:15px;
    animation:leftRight 1.7s ease-in-out;
}

@keyframes leftRight {
    0%{
        opacity:0;
        margin-left:-50px;
    }
    100%{
        opacity:1;
        margin-left:0px;

    }
}
.left__side .supText{
    font-size:18px;
    color:#155257;
    line-height:26px;
    z-index: 1;
}

.subText{
    max-width:500px;
    color:#155257;
    margin-top: 12px;
    font-weight:400;
}
.right__side{
    flex: 0.5;
    overflow:hidden;
}
.right__side > img {
    width:100%;
    object-fit: cover;
    height: 100%;
}