
.services{
    padding:20px 10px;
    margin: 0px auto;
    margin-bottom: 50px;
    max-width: 1000px;
    
}

.service{
    background-color: rgb(242, 242, 242);
    margin: 20px 0px;
    padding: 20px;
    border-radius: 10px;
}


.serviceHeading{
    font-size: 35px;
    color:rgb(255, 68, 0);
    padding-bottom:0px ;
    margin-bottom:20px ;
    width: fit-content;
    border-bottom: 2px solid red;
}

.serviceDetails{
    margin: 20px 0px;
    margin-top: 20px;
}
.list{
    margin-left: 30px;
    margin-top: 5px;
    font-size: 17px;
    margin-bottom: 15px;
}
.listHeading{
    font-size: 20px;
    font-weight: bold;
    color: rgb(82, 80, 80);
    margin-top: 25px;
}

.serviceAbout{
    font-size: 15px;
}

.orderHeading{
    font-size: 25px;
    font-weight: 900;
    margin-top: 15px;
}

.orderList{
    margin-left: 15px;
    margin-right: 15px;
}
