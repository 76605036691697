.new__section{
    padding:70px 0px;
}
.section__heading{
    background-color:#E8F6EF;
    font-size:28px;
    font-weight: 600;
    text-align: center;
    color: #456157;

}

.clients::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}
.clients{
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    max-width:1000px;
    margin:0px auto;
    padding:10px;
    grid-column-gap:-10px;
}

@media only screen and (max-width:600px){
    .clients{
        grid-template-columns:1fr 1fr ;
        grid-column-gap:10px;

    }
}


.client__card{
    margin:5px 5px;
    padding:5px;
    border-radius: 10px;
    max-width:180px;
    display:flex;
    justify-content:center;
    align-items:center;
    object-fit:contain;
    margin:0px auto;
}

.logos{
    max-width:100%;
}